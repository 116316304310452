import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import banners from "../../../datos/banner.json";

export class Banner extends Component {
  state = {
    nav1: null,
    nav2: null,
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-9">{this.renderHorizontal()}</div>
        {this.renderVertical()}
      </div>
    );
  }

  renderHorizontal = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <Slider
        {...settings}
        asNavFor={this.state.nav2}
        ref={(slider) => (this.slider1 = slider)}
      >
        {banners.map((banner) => {
          let imagen = banner.imagen_path;
          if (!!imagen) {
            imagen = `http://prueba.godspan.com.py${imagen}`;
          }
          return (
            <div>
              <div
                className="card-banner primary"
                style={{
                  backgroundImage: `url(${imagen}) `,
                }}
              >
                <article className="caption bottom">
                  <h5 className="card-title">{banner.titulo}</h5>
                  <p>{banner.descripcion}</p>
                </article>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  };

  renderVertical = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      centerPadding: "0",
    };

    let banner_nuevo = banners;
    let banner = banner_nuevo.shift();
    banner_nuevo.push(banner);

    return (
      <div
        className="col-md-3 d-none d-md-block"
        style={{
          maxHeight: " 351px",
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <Slider
          {...settings}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
        >
          {banner_nuevo.map((banner) => {
            let imagen = banner.imagen_path;
            if (!!imagen) {
              imagen = `http://prueba.godspan.com.py${imagen}`;
            }
            return (
              <div>
                <div
                  className="card-banner secondary"
                  style={{
                    backgroundImage: `url(${imagen}) `,
                  }}
                ></div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };
}

export default Banner;
