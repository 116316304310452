import React from "react";
import "react-owl-carousel2/src/owl.carousel.css"; //Allows for server-side rendering.
import "react-owl-carousel2/src/owl.theme.default.css"; //Allows for server-side rendering.

import { Router } from "./router";

const App = (props) => {
  const { history } = props;

  return <Router history={history} />;
};

export default App;
