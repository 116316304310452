import React, { Component } from 'react'

export class Informaciones extends Component {
    render() {
        return (
            <div className="row">
            <div className="col-md-4">
                <article className="card card-body">
                    <figure className="itemside">
                        <div className="aside">
                            <span className="icon-sm rounded-circle" style={{backgroundColor:"#2d261a"}}>
                                <i className="fa fa-truck white"></i>
                            </span>
                        </div>
                        <figcaption className="info">
                            <h5 className="title">Delivery</h5>
                            <p>
                                Con entrega a tu puerta
                            </p>
                        </figcaption>
                    </figure>
                </article>
            </div>
            <div className="col-md-4">
                <article className="card card-body">
                    <figure className="itemside">
                        <div className="aside">
                            <span className="icon-sm rounded-circle" style={{backgroundColor:"#2d261a"}}>
                                <i className="fa fa-clock white"></i>
                            </span>
                        </div>
                        <figcaption className="info">
                            <h5 className="title">Horario</h5>
                            <p>
                                Pedidos desde 07:00 a 20:00
                            </p>
                        </figcaption>
                    </figure>
                </article>
            </div>
            <div className="col-md-4">
                <article className="card card-body">
                    <figure className="itemside">
                        <div className="aside">
                            <span className="icon-sm rounded-circle" style={{backgroundColor:"#2d261a"}}>
                                <i className="fa fa-home white"></i>
                            </span>
                        </div>
                        <figcaption className="info">
                            <h5 className="title">Retiro en Tienda</h5>
                            <p>
                                Podes retirar una de nuestras tiendas
                            </p>
                        </figcaption>
                    </figure>
                </article>
            </div>
        </div>
        )
    }
}

export default Informaciones
