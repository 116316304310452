import React, { Component } from "react";
import { connect } from "react-redux";
import { AUTH_REQUEST } from "../handlers/constants";

class Login extends Component {
  state = {
    email: "angel.oliver.cv@gmail.com",
    password: "Hola1234",
  };

  onChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.dispatch({
      type: AUTH_REQUEST,
      payload: {
        email,
        password,
      },
    });
  };

  render() {
    return (
      <>
        <div>
          <section className="section-content">
            <div
              className="container padding-y-sm"
              style={{ marginBottom: "40vh" }}
            >
              <div className="row" style={{ justifyContent: "center" }}>
                <aside className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Ingresar</h4>
                      <form onSubmit={this.submit} action="/perfil">
                        <div className="form-group">
                          <label>Correo</label>
                          <input
                            name=""
                            className="form-control"
                            placeholder=""
                            type="email"
                            onChange={this.onChange("email")}
                            value={this.state.email}
                          />
                        </div>
                        <div className="form-group">
                          <a className="float-right" href="#">
                            Recuperar
                          </a>
                          <label>Constraseña</label>
                          <input
                            className="form-control"
                            placeholder="******"
                            type="password"
                            onChange={this.onChange("password")}
                            value={this.state.password}
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Ingresar{" "}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section className="section-content">
            <div className="container padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
