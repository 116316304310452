import React, { Component } from "react";
import { connect } from "react-redux";
import AreaMap from "../../../common/AreaMap";
import sucursales from "../../../datos/sucursales.json";
import { SET_DIRECCIONES } from "../handlers/constants";

class RegistroPage extends Component {
  state = {
    usuario: {},
    facturacion: {},
    sucursal: {},
    punto: {},
    direccion: {},
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { usuario = {}, facturacion = {}, direccion = {} } = this.state;
    const { sucursal = {} } = direccion;

    return (
      <>
        <div>
          <section className="section-content">
            <div className="container padding-y-sm">
              <form className="row" style={{ justifyContent: "center" }}>
                <div className="card col-md-9">
                  <div className="card-body">
                    <h4 className="card-title mb-3">Registro</h4>
                    <div className="form-row">
                      <div className="col-6 form-group">
                        <label>Nombre</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.nombre || ""}
                          onChange={this.onChangeUsuario("nombre")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Apellido</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.apellido || ""}
                          onChange={this.onChangeUsuario("apellido")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.email || ""}
                          onChange={this.onChangeUsuario("email")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Telefono</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.telefono_1 || ""}
                          onChange={this.onChangeUsuario("telefono_1")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Otro telefono</label>
                        <input
                          type="text"
                          className="form-control"
                          value={usuario.telefono_2 || ""}
                          onChange={this.onChangeUsuario("telefono_2")}
                        />
                      </div>
                    </div>
                    <h4 className="card-title mb-3">Datos de facturación</h4>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Razón social (opcional)</label>
                        <input
                          type="text"
                          className="form-control"
                          value={facturacion.razon_social || ""}
                          onChange={this.onChangeFacturacion("razon_social")}
                        />
                      </div>
                      <div className="col form-group">
                        <label>RUC (opcional)</label>
                        <input
                          type="email"
                          className="form-control"
                          value={facturacion.ruc || ""}
                          onChange={this.onChangeFacturacion("ruc")}
                        />
                      </div>
                    </div>
                    <h4 className="card-title mb-3">Contraseña</h4>
                    <div className="form-row">
                      <div className="col form-group">
                        <label>Nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value={usuario.clave || ""}
                          onChange={this.onChangeUsuario("clave")}
                        />
                      </div>
                      <div className="col form-group">
                        <label>Confirmar nueva contraseña</label>
                        <input
                          type="password"
                          className="form-control"
                          value={usuario.clave_confirmar || ""}
                          onChange={this.onChangeUsuario("clave_confirmar")}
                        />
                      </div>
                    </div>

                    <h4 className="card-title mb-3">Dirección</h4>
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <label>Dirección</label>
                        <input
                          type="text"
                          value={direccion.direccion || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("direccion")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Barrio</label>
                        <input
                          type="text"
                          value={direccion.barrio || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("barrio")}
                        />
                      </div>
                      <div className="col-6 form-group">
                        <label>Ciudad</label>
                        <input
                          type="text"
                          value={direccion.ciudad || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("ciudad")}
                        />
                      </div>
                      <div className="col-12 form-group">
                        <label>Referencia</label>
                        <input
                          type="text"
                          value={direccion.referencia || ""}
                          className="form-control"
                          onChange={this.onChangeDireccionEditar("referencia")}
                        />
                      </div>
                      <div className="col-12 form-group">
                        <label>Sucursal asignada</label>
                        <input
                          type="readonly"
                          value={sucursal.name || ""}
                          className="form-control"
                        />
                      </div>

                      <div className="col-12 form-group">
                        <AreaMap
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAGamRMBNFBB-x7Wrl-SSq-t4rqQiGRgBI"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `400px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          defaultCenter={{ lat: -25.3011551, lng: -57.580372 }}
                          zoom={4}
                          areas={sucursales}
                          point={direccion.punto}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <center>
                      <button className="btn btn-primary">Registrarme</button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
            <div className="container padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }

  onChange = (id, point) => {
    let { direccion = {} } = this.state;
    direccion.sucursal = sucursales[id] || {};
    direccion.punto = point || {};

    console.log({ id, direccion });
    this.setState({ direccion });
  };

  onChangeUsuario = (name) => (event) => {
    let { usuario = {} } = this.state;
    usuario[name] = event.target.value;
    this.setState({ usuario });
  };

  onChangeFacturacion = (name) => (event) => {
    let { facturacion = {} } = this.state;
    facturacion[name] = event.target.value;
    this.setState({ facturacion });
  };

  onChangeDireccionEditar = (name) => (event) => {
    let { direccion = {} } = this.state;
    direccion[name] = event.target.value;
    this.setState({ direccion });
  };
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  direcciones: state.auth.direcciones,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistroPage);
