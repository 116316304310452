import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ProductoCard } from "./ProductoCard";
import productos_destacadas from "../../../datos/productos_destacadas.json";
import OwlCarousel from "react-owl-carousel2";

export class ProductoCarousel extends Component {
  options = {
    items: 1.5,
    nav: false,
    loop: true,
    margin: 20,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1.5,
        nav: false,
        loop: true,
      },
      776: {
        items: 2.5,
        nav: false,
        loop: true,
      },
      1000: {
        items: 4,
        nav: false,
        loop: true,
      },
    },
  };

  events = {
    onDragged: function (event) {},
    onChanged: function (event) {},
    onInitialized: function (event) {
      let owl = window.$(event.target);

      owl.on("mousewheel", ".owl-stage", function (e) {
        if (e.deltaY > 0) {
          owl.trigger("next.owl");
        } else {
          owl.trigger("prev.owl");
        }
        e.preventDefault();
      });
    },
  };

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <OwlCarousel options={this.options} events={this.events}>
            {productos_destacadas.map((producto) => (
              <div>
                <ProductoCard {...producto}></ProductoCard>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default ProductoCarousel;
