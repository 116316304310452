import React, { Component } from 'react'

export class ProductoDescripcion extends Component {
    render() {
        return (
            <article className="card">
                <div className="card-body">
                    <div className="row">


                        <aside className="col-md-12" style={{paddingBottom:"10px"}}>
                            <h5>Descripción</h5>
                        </aside>
                        <aside className="col-md-12" style={{paddingBottom:"10px"}}>
                            <span>Producto no acto para celiacos </span><br /><br />
                            Ingrediente: <br />
                            harina de trigo 800 grs,
                            agua 300 cc,
                            sal fina 20 g,
                            materia grasa 250 g,
                            levadura fresca 25 g,
                            azúcar 20 gr,
                            anís en grano 1 cucharadita
                        </aside>
                    </div>

                </div>
            </article>
        )
    }
}

export default ProductoDescripcion
