import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_LOGOUT,
  SET_DIRECCIONES,
} from "./constants";

const initialState = {
  token: "", // localStorage.getItem('socialProof.token'),
  user: "", // localStorage.getItem('socialProof.user')
  direcciones: [],
};

export const authReducer = (state = initialState, { type, payload, user }) => {
  switch (type) {
    case AUTH_SUCCESS: {
      return {
        ...state,
        token: payload,
        user,
        direcciones: user.direcciones || [],
      };
    }

    case AUTH_FAILURE: {
      return { ...state, error: payload };
    }
    case AUTH_LOGOUT: {
      return { ...state, token: null, user: null, direcciones: [] };
    }

    case SET_DIRECCIONES: {
      return { ...state, direcciones: payload.direcciones };
    }

    default:
      return state;
  }
};
