import axios from "axios";
const API_URL = "http://prueba.godspan.com.py/api";
export const loginApi = (token) => ({
  login: async (email, password) => {
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      email,
      password,
    };

    let response = await axios.post(`${API_URL}/auth/login`, body, options);
    console.log({ response });
    try {
      return { token: response.data.access_token };
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },

  me: async (token) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {};

    let response = await axios.post(`${API_URL}/auth/me`, body, options);
    console.log({ response });
    try {
      return response.data;
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },

  direcciones: async (token, direcciones) => {
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let body = {
      direcciones: JSON.stringify(direcciones),
    };

    let response = await axios.post(
      `${API_URL}/auth/direcciones`,
      body,
      options
    );

    try {
      return { token: response.data.token, user: response.data.user };
    } catch (error) {
      console.log({ error });
      return error.response ? error.response.data : error;
    }
  },
});
