import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_ORDENES } from "../handlers/constants";
import numeral from "numeral";

class OrdenPage extends Component {
  state = {
    ordenes: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch({ type: GET_ORDENES });
  }

  render() {
    return (
      <>
        <div>
          <section className="section-content">
            {this.renderSeccionOrden()}
            <div className="container padding-y-sm"></div>
          </section>
        </div>
      </>
    );
  }

  renderSeccionOrden = () => {
    return (
      <div className="container padding-y-sm">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="card col-md-9">
            <div className="card-body">
              <h4 className="card-title mb-3">Mis Ordenes </h4>
              {this.renderMisOrdenes()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMisOrdenes = () => {
    let { ordenes = [] } = this.props;

    return (
      <div className="" style={{ paddingTop: "0px" }}>
        {ordenes.map((orden) => (
          <OrdenItem orden={orden}></OrdenItem>
        ))}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  ordenes: state.carrito.ordenes,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdenPage);

class OrdenItem extends React.Component {
  state = {
    open: true,
  };
  render() {
    const { orden = {} } = this.props;

    let envio = orden.envio === "delivery" ? 15000 : 0;

    let montos = this.props.orden.carrito.map(
      (item) => item.producto.precio * item.cantidad
    );
    let total = [...montos, 0, 0].reduce((p, c) => p + c);

    let fecha = orden.created_at.split("T").shift();
    fecha = fecha.split("-");
    let day = fecha.pop();
    let month = fecha.pop();
    let year = fecha.pop();

    fecha = day + "/" + month + "/" + year;

    return (
      <div style={{ marginBottom: "10px" }}>
        <a
          key={orden.id}
          className="card"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div
            className="card-body"
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <h3
              classnAME="card-title  no-gutters"
              style={{ marginBottom: "0px", textTransform: "capitalize" }}
            >
              {fecha} -{orden.envio}
            </h3>

            <i
              className={
                "fa" + (this.state.open ? " fa-angle-down" : " fa-angle-up")
              }
            ></i>
          </div>
        </a>
        {this.state.open && (
          <div className="row no-gutters">
            <aside className="col-md-12">
              {orden.carrito.map((item, idx) => {
                const { lista_fotos } = item.producto;
                let imagen = lista_fotos[0] || {};
                let { foto } = imagen;

                return (
                  <article
                    key={idx + "_" + item.producto.id}
                    className="card-body border-bottom"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <figure className="media">
                          <div className="img-wrap mr-3">
                            <img
                              className="border img-sm w-80px"
                              src={`${foto}`}
                            />
                          </div>
                          <figcaption className="media-body">
                            <a href="#" className="title h6">
                              {item.producto.nombre}
                            </a>
                            <div className="price-wrap">
                              {item.cantidad} x Gs.{" "}
                              {numeral(item.producto.precio)
                                .format("0,0")
                                .replace(",", ".")}
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </article>
                );
              })}
              <article className="card-body">
                <dl className="row">
                  <dt className="col-6 col-sm-6">Subtotal: </dt>
                  <dd className="col-6 col-sm-6 text-right">
                    <strong>
                      Gs. {numeral(total).format("0,0").replace(",", ".")}
                    </strong>
                  </dd>

                  <dt className="col-6 col-sm-6">Costo de envio: </dt>
                  <dd className="col-6 col-sm-6 text-right">
                    <strong>
                      Gs. {numeral(envio).format("0,0").replace(",", ".")}
                    </strong>
                  </dd>

                  <dt className="col-sm-6">Total:</dt>
                  <dd className="col-sm-6 text-right">
                    <strong className="h5 text-dark">
                      Gs.{" "}
                      {numeral(total + envio)
                        .format("0,0")
                        .replace(",", ".")}
                    </strong>
                  </dd>
                </dl>
              </article>
            </aside>
          </div>
        )}
      </div>
    );
  }
}
