import React, { Component } from "react";
import { Link } from "react-router-dom";

import categorias from "../../../datos/categorias.json";

export class CategoriaFiltro extends Component {
  render() {
    let { categoria } = this.props;
    let categorias_hasta_mi = categorias.filter((c) =>
      categoria.hasta_mi.includes(c.id)
    );
    console.log(categorias_hasta_mi);
    let hermanos = categorias
      .filter((c) => c.principal == true && c.id != this.props.categoria.id)
      .map((c) => c.id);

    return (
      <div className="card">
        <article className="filter-group">
          <div className="filter-content collapse show" id="collapse_1">
            <div className="card-body">
              <ul className="list-menu" style={{ textTransform: "capitalize" }}>
                {categoria.hasta_mi.map((categoria_id) => {
                  let categoria = categorias_hasta_mi
                    .filter((c) => c.id == categoria_id)
                    .shift();

                  if (categoria.id == this.props.categoria.id) {
                    return (
                      <li>
                        <b>{categoria.nombre.toLocaleLowerCase()}</b>{" "}
                      </li>
                    );
                  }

                  hermanos = categoria.hijos || [];
                  hermanos = hermanos.filter(
                    (h) => h != this.props.categoria.id
                  );
                  return (
                    <li>
                      <Link
                        to={`/categoria/${categoria.id}/${categoria.nombre}`}
                      >
                        {"<"} {categoria.nombre.toLocaleLowerCase()}
                      </Link>{" "}
                    </li>
                  );
                })}
                {(categoria.hijos || []).map((categoria_id) => {
                  let categoria = categorias
                    .filter((c) => c.id == categoria_id)
                    .shift();
                  return (
                    <li>
                      <Link
                        to={`/categoria/${categoria.id}/${categoria.nombre}`}
                      >
                        {"-"} {categoria.nombre.toLocaleLowerCase()}
                      </Link>{" "}
                    </li>
                  );
                })}

                {(hermanos || []).map((categoria_id) => {
                  let categoria = categorias
                    .filter((c) => c.id == categoria_id)
                    .shift();
                  return (
                    <li>
                      <Link
                        to={`/categoria/${categoria.id}/${categoria.nombre}`}
                      >
                        {categoria.nombre.toLocaleLowerCase()}
                      </Link>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </article>

        <article className="filter-group">
          <header className="card-header">
            <Link
              to="#"
              data-toggle="collapse"
              data-target="#collapse_3"
              aria-expanded="true"
              className=""
            >
              <i className="icon-control fa fa-chevron-down"></i>
              <h6 className="title">Rango de precio </h6>
            </Link>
          </header>
          {/* <div className="filter-content collapse" id="collapse_3">
            <div className="card-body">
              <input
                type="range"
                className="custom-range"
                min="0"
                max="100"
                name=""
              />
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Min</label>
                  <input
                    className="form-control"
                    placeholder="0"
                    type="number"
                  />
                </div>
                <div className="form-group text-right col-md-6">
                  <label>Max</label>
                  <input
                    className="form-control"
                    placeholder="500"
                    type="number"
                  />
                </div>
              </div>
              <button className="btn btn-block btn-primary">Aplicar</button>
            </div>
          </div> */}
        </article>
      </div>
    );
  }
}

export default CategoriaFiltro;
