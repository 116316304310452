import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <footer
        className="section-footer border-top bg"
        style={{ color: "#f0f0f0", backgroundColor: "#2d261a" }}
      >
        <div className="container">
          <section className="footer-top padding-y" style={{ padding: "15px" }}>
            <div className="row">
              <aside
                className="col-md col-12 padding-y-sm"
                style={{ textAlign: "center" }}
              >
                <Link to="#" className="brand-wrap">
                  <img
                    className="logo"
                    src="/resource/logo-light-icon.png"
                    style={{ maxHeight: "90px" }}
                  />
                </Link>
              </aside>
              <aside className="col-md col-6 d-xs-none d-none d-md-block">
                &nbsp;
              </aside>
              <aside className="col-md col-6">
                <h5 className="title">Empresa</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="#">Nosotros</Link>
                  </li>
                  <li>
                    <Link to="#">Sucursales</Link>
                  </li>
                  <li>
                    <Link to="#">Terminos y condiciones</Link>
                  </li>
                  <li>
                    <Link to="#">Contactos</Link>
                  </li>
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h5 className="title">Cuenta</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="#"> Ingresar </Link>
                  </li>
                  <li>
                    <Link to="#"> Registrarse </Link>
                  </li>
                  <li>
                    <Link to="#"> Mi cuenta </Link>
                  </li>
                  <li>
                    <Link to="/ordenes"> Mis ordenes </Link>
                  </li>
                </ul>
              </aside>
              <aside className="col-md col-6">
                <h5 className="title">Social</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="#">
                      {" "}
                      <i className="fab fa-facebook"></i> Facebook{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {" "}
                      <i className="fab fa-twitter"></i> Twitter{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {" "}
                      <i className="fab fa-instagram"></i> Instagram{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {" "}
                      <i className="fab fa-youtube"></i> Youtube{" "}
                    </Link>
                  </li>
                </ul>
              </aside>
            </div>
          </section>

          <section className="footer-bottom row">
            <div className="col-md-2">
              <p className="text-muted">© 2020 God's Pan</p>
            </div>
            <div className="col-md-8 text-md-center">
              <span className="px-2">info@godspan.com.py</span>
              <span className="px-2">(021) 281 828 / (021) 280 212</span>
              <span className="px-2">
                Avda. Santísima Trinidad 3164 esq. Bogota
              </span>
            </div>
            <div className="col-md-2 text-md-right text-muted">
              <i className="fab fa-lg fa-cc-visa"></i>
              <i className="fab fa-lg fa-cc-paypal"></i>
              <i className="fab fa-lg fa-cc-mastercard"></i>
            </div>
          </section>
        </div>
      </footer>
    );
  }
}

export default Footer;
