import { AUTH_LOGOUT, AUTH_SUCCESS } from "../../Acceso/handlers/constants";
import { GET_ORDENES, SET_CARRITO_ITEMS, _SUCCESS } from "./constants";

const initialState = {
  carrito_items: [],
  ordenes: [],
};

export const carritoReducer = (
  state = initialState,
  { type, payload, user }
) => {
  switch (type) {
    case SET_CARRITO_ITEMS: {
      return { ...state, carrito_items: payload.carrito_items };
    }

    case AUTH_SUCCESS: {
      return { ...state, carrito_items: user.carrito || [] };
    }

    case AUTH_LOGOUT: {
      return { ...state, carrito_items: [] };
    }

    case GET_ORDENES + _SUCCESS: {
      console.log({ payload });
      return { ...state, ordenes: payload.ordenes, carrito_items: [] };
    }

    default:
      return state;
  }
};
