import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  SET_DIRECCIONES,
} from "./constants";
import { loginApi } from "./api";
import { push } from "react-router-redux";

function* authorize(action) {
  console.log(action);
  const Api = loginApi();

  try {
    const { token } = yield call(
      Api.login,
      action.payload.email,
      action.payload.password
    );

    const user = yield call(Api.me, token);

    yield put({ type: AUTH_SUCCESS, payload: token, user });
    localStorage.setItem("socialProof.token", token);
    localStorage.setItem("socialProof.user", JSON.stringify(user));
    // action.callbackSuccess();
    yield put(push("/carrito"));
  } catch (error) {
    const { errors = {} } = error;
    yield put({ type: AUTH_FAILURE, payload: errors.message || error });
    localStorage.removeItem("token");
    // action.callbackError(errors.message);
  }
}

function* logout(action) {
  localStorage.removeItem("socialProof.token");
  localStorage.removeItem("socialProof.user");
  yield put({ type: AUTH_LOGOUT });
  // action.callbackSuccess();
}

function* setDirecciones(action) {
  const token = yield select((state) => state.auth.token);
  const Api = loginApi();

  try {
    yield call(Api.direcciones, token, action.payload.direcciones);
  } catch (error) {
    console.log({ error });
  }
}

function* accesoSaga() {
  yield takeLatest(SET_DIRECCIONES, setDirecciones);
  yield takeLatest(AUTH_REQUEST, authorize);
  yield takeLatest(AUTH_LOGOUT_REQUEST, logout);
}

export default accesoSaga;
