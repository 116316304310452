import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductoGaleria from "./ProductoGaleria";

export class ProductoDetalle extends Component {
  render() {
    return (
      <main className="producto-detalle card">
        <article className="content-body">
          <h2 className="title" style={{ textTransform: "capitalize" }}>
            {this.props.nombre.toLowerCase()}
          </h2>

          <ul className="list-normal cols-two">
            <li>Se especial para comer jamón, queso, pastas y salsas </li>
            <li>Largo, delgado y excelente para picar </li>
            <li>Harina de trigo </li>
          </ul>
        </article>
      </main>
    );
  }
}

export default ProductoDetalle;
