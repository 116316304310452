import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductoDetalle from "../components/ProductoDetalle";
import ProductoDescripcion from "../components/ProductoDescripcion";
import ProductoInformacionNutricional from "../components/ProductoInformacionNutricional";
import ProductoGaleria from "../components/ProductoGaleria";
import numeral from "numeral";

import productos from "../../../datos/productos.json";
import { setCarritoItems } from "../../Carrito/handlers/actions";
import { connect } from "react-redux";

export class ProductoPage extends Component {
  state = {
    cantidad: 1,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let datos = productos;
    let { match = {} } = this.props;
    let { params = {} } = match;

    let producto = productos.filter((c) => c.id == params.producto).shift();

    return (
      <div>
        <section className="section-content">
          <div className="container padding-y-sm">
            <div className="producto-detalle row">
              <div className="col-sm-12 col-md-4 padding-bottom-sm">
                <div>
                  <ProductoGaleria {...producto}></ProductoGaleria>
                </div>
                {/* <div className="padding-y-sm">
                  <ProductoDescripcion {...producto}></ProductoDescripcion>
                </div> */}
                <div className="padding-y-sm">
                  <ProductoInformacionNutricional
                    {...producto}
                  ></ProductoInformacionNutricional>
                </div>
              </div>
              <div className="col-sm-12 col-md-8 padding-bottom-sm">
                <div>
                  <ProductoDetalle {...producto}></ProductoDetalle>
                </div>
                <div className="padding-y-sm">
                  <ProductoDescripcion {...producto}></ProductoDescripcion>
                </div>
                {/* <div className="padding-y-sm">
                  <ProductoComboDetalle></ProductoComboDetalle>
                </div>
                <div className="padding-y-sm">
                  <ProductoAgregados></ProductoAgregados>
                </div> */}

                <div className="padding-y-sm">
                  {" "}
                  <main className="producto-detalle card">
                    <article className="content-body">
                      <div className="h3 mb-4">
                        <var className="price h4">
                          Gs.{" "}
                          {numeral(producto.precio)
                            .format("0,0")
                            .replace(",", ".")}
                        </var>
                      </div>

                      <div className="form-row">
                        <div className="col-2">Cantidad:</div>
                        <div className="col">
                          <div className="input-group input-spinner">
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-light"
                                type="button"
                                id="button-plus"
                                onClick={() =>
                                  this.setState({
                                    cantidad: this.state.cantidad + 1,
                                  })
                                }
                              >
                                {" "}
                                +{" "}
                              </button>
                            </div>
                            <input
                              type="text"
                              readonly=""
                              className="form-control"
                              value={this.state.cantidad}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-light"
                                type="button"
                                id="button-minus"
                                onClick={() =>
                                  this.setState({
                                    cantidad:
                                      this.state.cantidad > 1
                                        ? this.state.cantidad - 1
                                        : 1,
                                  })
                                }
                              >
                                {" "}
                                −{" "}
                              </button>
                            </div>
                          </div>{" "}
                          <a
                            onClick={() =>
                              this.addCarritoItems(
                                producto,
                                this.state.cantidad
                              )
                            }
                            className="btn  btn-primary w-50"
                          >
                            {" "}
                            <span className="text">Agregar</span>{" "}
                            <i className="fas fa-shopping-cart"></i>{" "}
                          </a>
                        </div>
                      </div>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  addCarritoItems = (producto, cantidad) => {
    let carrito_items = [...this.props.carrito_items, { producto, cantidad }];
    this.props.setCarritoItems(carrito_items);
  };
}

const mapStateToProps = (state) => ({
  carrito_items: state.carrito.carrito_items,
});

const mapDispatchToProps = (dispatch) => ({
  setCarritoItems: (carrito_items) => {
    dispatch(setCarritoItems(carrito_items));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductoPage);
