import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  CARRITO_PAGO,
  GET_ORDENES,
  SET_CARRITO_ITEMS,
  _SUCCESS,
} from "./constants";
import { push } from "react-router-redux";
import { carritoApi } from "./api";

function* setCarritoItems(action) {
  const token = yield select((state) => state.auth.token);

  if (!!token) {
    const Api = carritoApi(token);

    try {
      yield call(Api.save, action.payload.carrito_items);
    } catch (error) {
      console.log({ error });
    }
  }

  yield put(push("/carrito"));
}

function* carritoPago(action) {
  const token = yield select((state) => state.auth.token);

  if (!!token) {
    const Api = carritoApi(token);
    try {
      let pago_info = yield call(Api.pago, {
        carrito: action.payload.carrito_items,
        envio: action.payload.envio,
        direccion: action.payload.direccion,
        sucursal: action.payload.sucursal,
      });
      console.log({ pago_info });
      window.open(pago_info.enlace);
    } catch (error) {
      console.log({ error });
    }
  }

  yield put(push("/"));
}

function* getOrdenes() {
  console.log("ordenes");

  const token = yield select((state) => state.auth.token);

  if (!!token) {
    const Api = carritoApi(token);
    let data = yield call(Api.ordenes, {});

    data.ordenes = data.ordenes.map((item) => {
      item.direccion = JSON.parse(item.direccion);
      item.carrito = JSON.parse(item.carrito);

      return item;
    });

    yield put({
      type: GET_ORDENES + _SUCCESS,
      payload: data,
    });
  }
}

function* carritoSaga() {
  yield takeLatest(SET_CARRITO_ITEMS, setCarritoItems);
  yield takeLatest(CARRITO_PAGO, carritoPago);
  yield takeLatest(GET_ORDENES, getOrdenes);
}

export default carritoSaga;
