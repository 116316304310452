import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import categorias_destacadas from "../datos/categorias_destacadas.json";
import { AUTH_LOGOUT_REQUEST } from "../modules/Acceso/handlers/constants";

export class Header extends Component {
  state = {
    login: false,
    collapse: true,
  };

  constructor(props) {
    super();
  }

  render() {
    const { collapse = true } = this.state;

    return (
      <div>
        <header className="section-header">
          <section className="header-main">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-12 col-12">
                  <Link to="/" className="brand-wrap">
                    <img
                      className="logo"
                      src="/resource/logo-marca-yellow.png"
                      style={{ maxHeight: "45px" }}
                    />
                  </Link>
                </div>
                <div className="col-lg-5 col-12 col-sm-12">
                  <form action="#" className="search">
                    <div className="input-group w-100">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar un producto"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="widgets-wrap float-md-right">
                    <div className="widget-header mr-3">
                      <Link
                        to="/carrito"
                        className="icon icon-sm rounded-circle border"
                      >
                        <i className="fa fa-shopping-cart"></i>
                      </Link>
                      <span className="badge badge-pill badge-danger notify">
                        {this.props.carrito_items.length}
                      </span>
                    </div>
                    <div className="widget-header icontext">
                      {!!this.props.token && (
                        <>
                          <Link
                            to="/perfil"
                            className="icon icon-sm rounded-circle border"
                          >
                            <i className="fa fa-user"></i>
                          </Link>
                          <div className="text">
                            <div>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.props.dispath({
                                    type: AUTH_LOGOUT_REQUEST,
                                  })
                                }
                              >
                                Salir
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                      {!this.props.token && (
                        <div className="text">
                          <div>
                            <Link to="/login">Ingresar</Link> |
                            <Link to="/registro"> Registro</Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>

        <nav className="navbar navbar-main navbar-expand-lg navbar-light border-bottom">
          <div className="container">
            <div
              className="row align-items-center no-margin"
              style={{ width: "100%" }}
            >
              <div className="col-lg-3 col-6"></div>
              <div className="col-lg-9 col-6 no-padding">
                <button
                  onClick={() => {
                    console.log({ collapse });
                    this.setState({ collapse: !collapse });
                  }}
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#main_nav"
                  aria-controls="main_nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className={(collapse ? "collapse" : "") + " navbar-collapse"}
                  id="main_nav"
                >
                  <ul className="navbar-nav">
                    {categorias_destacadas.map((categoria) => (
                      <li
                        className="nav-item"
                        onClick={() => {
                          this.setState({ collapse: true });
                        }}
                      >
                        <Link
                          className="nav-link"
                          style={{ textTransform: "capitalize" }}
                          to={`/categoria/${categoria.id}/${categoria.nombre}`}
                        >
                          {categoria.nombre.toLocaleLowerCase()}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  carrito_items: state.carrito.carrito_items,
  token: state.auth.token,
});

export default connect(mapStateToProps, (dispath) => ({ dispath }))(Header);
