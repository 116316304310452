import React, { Component } from "react";
import { Link } from "react-router-dom";
import CategoriaHeader from "../components/CategoriaHeader";
import ProductoCard from "../../Producto/components/ProductoCard";
import CategoriaFiltro from "../components/CategoriaFiltro";
import productos from "../../../datos/productos.json";
import categorias from "../../../datos/categorias.json";

export class CategoriaPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(newProps) {
    let { match = {} } = this.props;
    let { params = {} } = match;

    let { newMatch = {} } = newProps;
    let { newParams = {} } = newMatch;

    if (params.categoria != newParams.categoria) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    let { match = {} } = this.props;
    let { params = {} } = match;

    let categoria = categorias.filter((c) => c.id == params.categoria).shift();

    return (
      <div>
        <CategoriaHeader nombre={categoria.nombre}></CategoriaHeader>
        <section className="section-content">
          <div className="container padding-y-sm">
            <div className="row">
              <aside className="col-md-3">
                <CategoriaFiltro categoria={categoria}></CategoriaFiltro>
              </aside>
              <main className="col-md-9">
                {this.renderResultadoInfo()}
                {this.renderResultadoProductos()}
                {this.renderResultadoPaginacion()}
              </main>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderResultadoInfo = () => {
    let datos = productos;
    let { match = {} } = this.props;
    let { params = {} } = match;

    let categoria = categorias.filter((c) => c.id == params.categoria).shift();

    datos = datos.filter((p) => p.categorias.includes(categoria.id));
    console.log(this.props.match.params.categoria);

    return (
      <header className=" pb-3">
        <div className="form-inline">
          <span className="mr-md-auto">
            {datos.length} Productos encontrados{" "}
          </span>
          <div className="btn-group">
            <Link
              to="#"
              className="btn btn-outline-secondary"
              data-toggle="tooltip"
              title=""
              data-original-title="List view"
            >
              <i className="fa fa-bars"></i>
            </Link>
            <Link
              to="#"
              className="btn  btn-outline-secondary active"
              data-toggle="tooltip"
              title=""
              data-original-title="Grid view"
            >
              <i className="fa fa-th"></i>
            </Link>
          </div>
        </div>
      </header>
    );
  };

  renderResultadoPaginacion = () => {
    return;
    // <nav className="mt-4" aria-label="Page navigation sample">
    //   <ul className="pagination">
    //     <li className="page-item disabled">
    //       <Link className="page-link" to="#">
    //         Anterior
    //       </Link>
    //     </li>
    //     <li className="page-item active">
    //       <Link className="page-link" to="#">
    //         1
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         2
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         3
    //       </Link>
    //     </li>
    //     <li className="page-item">
    //       <Link className="page-link" to="#">
    //         Siguiente
    //       </Link>
    //     </li>
    //   </ul>
    // </nav>;
  };

  renderResultadoProductos = () => {
    let datos = productos;
    let { match = {} } = this.props;
    let { params = {} } = match;

    let categoria = categorias.filter((c) => c.id == params.categoria).shift();

    datos = datos.filter((p) => {
      return p.categorias.includes(categoria.id);
    });
    console.log(this.props.match.params.categoria);

    return (
      <div className="row">
        {datos.map((producto) => (
          <div className="col-md-4" key={producto.id}>
            <ProductoCard {...producto}></ProductoCard>
          </div>
        ))}
      </div>
    );
  };
}

export default CategoriaPage;
