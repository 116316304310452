import React, { Component } from "react";
import Banner from "../components/Banner";
import CategoriaCarousel from "../../Categoria/components/CategoriaCarousel";
import ProductoCarousel from "../../Producto/components/ProductoCarousel";
import Informaciones from "../components/Informaciones";

export class Main extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {this.renderBanner()}
        {this.renderCategorias()}
        {this.renderProductos()}
        {this.renderInformaciones()}
      </div>
    );
  }

  renderBanner = () => {
    return (
      <section className="section-intro">
        <div className="container padding-y-sm">
          <Banner></Banner>
        </div>
      </section>
    );
  };

  renderCategorias = () => {
    return (
      <section className="section-intro">
        <div className="container padding-y-sm">
          <header className="section-heading">
            <h3>Compra por categorias</h3>
          </header>
          <CategoriaCarousel></CategoriaCarousel>
        </div>
      </section>
    );
  };

  renderProductos = () => {
    return (
      <section className="section-intro">
        <div className="container padding-y-sm">
          <header className="section-heading">
            <h3>Productos destacados</h3>
          </header>
          <ProductoCarousel></ProductoCarousel>
        </div>
      </section>
    );
  };

  renderInformaciones = () => {
    return (
      <section className="section-intro">
        <div className="container padding-y-sm">
          <Informaciones></Informaciones>
        </div>
      </section>
    );
  };
}

export default Main;
