import React, { Component } from 'react'

export class CategoriaHeader extends Component {
    render() {
        return (
            <section className="categoria-header section-content bg">
                <div className="container">
                    <header className="section-heading">
                        <i>
                            <h3 className="section-title">
                                <img src="/resource/logo-marca-yellow.png" />
                                <span>{this.props.nombre?this.props.nombre:"Productos"}</span>
                            </h3>
                        </i>
                    </header>
                </div>
            </section>
        )
    }
}

export default CategoriaHeader
