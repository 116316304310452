import React, { Component } from "react";
import { connect } from "react-redux";
import { setCarritoItems } from "../handlers/actions";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { CARRITO_PAGO } from "../handlers/constants";

class CarritoPage extends Component {
  state = {
    envio: "",
    direccion_idx: null,
    sucursal_idx: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  crearEnlacePago = () => {
    let { envio, direccion_idx, sucursal_idx } = this.state;
    let { carrito_items, direcciones } = this.props;

    this.props.dispatch({
      type: CARRITO_PAGO,
      payload: {
        carrito_items,
        envio: envio,
        direccion: direccion_idx > -1 ? direcciones[direccion_idx] : null,
        sucursal: !!sucursal_idx ? sucursal_idx : null,
      },
    });
  };

  render() {
    return (
      <>
        <div>
          <section className="section-content">
            <div className="container padding-y-sm">
              <div className="row">
                <aside className="col-md-6 padding-bottom-sm">
                  {this.renderDetalle()}
                </aside>
                <aside className="col-md-6">{this.renderPago()}</aside>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }

  renderDetalle = () => {
    let montos = this.props.carrito_items.map(
      (item) => item.producto.precio * item.cantidad
    );
    let total = [...montos, 0, 0].reduce((p, c) => p + c);
    let envio = this.state.envio == "delivery" ? 15000 : 0;

    return (
      <>
        <div className="card">
          <div class="card-body border-bottom">
            <h4
              className="card-title  no-gutters"
              style={{
                marginBottom: "0px",
              }}
            >
              Carrito de compras
            </h4>
          </div>
          <div className="row no-gutters">
            <aside className="col-md-12">
              {this.props.carrito_items.map((item, idx) => {
                const { lista_fotos } = item.producto;
                let imagen = lista_fotos[0] || {};
                let { foto } = imagen;

                return (
                  <article
                    key={idx + "_" + item.producto.id}
                    className="card-body border-bottom"
                  >
                    <div className="row">
                      <div className="col-md-7">
                        <figure className="media">
                          <div className="img-wrap mr-3">
                            <img
                              className="border img-sm w-80px"
                              src={`${foto}`}
                            />
                          </div>
                          <figcaption className="media-body">
                            <a href="#" className="title h6">
                              {item.producto.nombre}
                            </a>
                            <div className="price-wrap">
                              Gs.{" "}
                              {numeral(item.producto.precio * item.cantidad)
                                .format("0,0")
                                .replace(",", ".")}
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                      <div className="col-md-5 text-md-right text-right">
                        <div className="input-group input-spinner">
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-light"
                              type="button"
                              id="button-plus"
                              onClick={() =>
                                this.updateCarritoItem(idx, item.cantidad + 1)
                              }
                            >
                              {" "}
                              +{" "}
                            </button>
                          </div>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            value={item.cantidad}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-light"
                              type="button"
                              id="button-minus"
                              onClick={() =>
                                this.updateCarritoItem(
                                  idx,
                                  item.cantidad > 1 ? item.cantidad - 1 : 1
                                )
                              }
                            >
                              {" "}
                              −{" "}
                            </button>
                          </div>
                        </div>
                        <a
                          href="#"
                          className="btn btn-light"
                          onClick={() => this.removeCarritoItem(idx)}
                        >
                          {" "}
                          <i className="fa fa-trash"></i>{" "}
                        </a>
                      </div>
                    </div>
                  </article>
                );
              })}
              <article className="card-body">
                <dl className="row">
                  <dt className="col-sm-6">Subtotal: </dt>
                  <dd className="col-sm-6 text-right">
                    <strong>
                      Gs. {numeral(total).format("0,0").replace(",", ".")}
                    </strong>
                  </dd>

                  <dt className="col-sm-6">Costo de envio: </dt>
                  <dd className="col-sm-6 text-right">
                    <strong>
                      Gs. {numeral(envio).format("0,0").replace(",", ".")}
                    </strong>
                  </dd>

                  <dt className="col-sm-6">Total:</dt>
                  <dd className="col-sm-6 text-right">
                    <strong className="h5 text-dark">
                      Gs.{" "}
                      {numeral(total + envio)
                        .format("0,0")
                        .replace(",", ".")}
                    </strong>
                  </dd>
                </dl>
              </article>
            </aside>
          </div>
        </div>
      </>
    );
  };

  renderPago = () => {
    return (
      <>
        <div class="card mb-4">
          <div class="card-body">
            <h4 class="card-title mb-4">Metodo de envio</h4>
            <form>
              <div class="form-group">
                <label class="custom-control custom-radio custom-control-inline">
                  <input
                    class="custom-control-input"
                    checked=""
                    type="radio"
                    name="envio"
                    checked={this.state.envio === "retirar"}
                    onChange={(event) =>
                      this.setState({ envio: event.target.value })
                    }
                    value="retirar"
                  />
                  <span class="custom-control-label">
                    {" "}
                    Retirar de un local <br />
                    Gratis{" "}
                  </span>
                </label>
                <label class="custom-control custom-radio custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="radio"
                    name="envio"
                    checked={this.state.envio === "delivery"}
                    onChange={(event) =>
                      this.setState({ envio: event.target.value })
                    }
                    value="delivery"
                  />
                  <span class="custom-control-label">
                    {" "}
                    Envio a domicilio <br /> Gs. 15.000{" "}
                  </span>
                </label>
              </div>
            </form>
          </div>
        </div>
        {!!this.state.envio && (
          <>
            {this.state.envio == "retirar" && this.renderRetirar()}
            {this.state.envio == "delivery" && this.renderDelivery()}
            {this.renderFacturacion()}
            {this.renderFormaPago()}

            <div class="card mb-4">
              <div class="card-body">
                <div class="form-group">
                  <button
                    onClick={this.crearEnlacePago}
                    type="button"
                    class="btn btn-primary btn-block"
                  >
                    {" "}
                    Continuar{" "}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  renderFacturacion = () => {
    return (
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title mb-4">Datos de Facturación</h4>
          <form>
            <div class="form-row">
              <div class="col form-group">
                <label>Razón Social</label>
                <input type="text" class="form-control" placeholder="" />
              </div>
              <div class="col form-group">
                <label>RUC</label>
                <input type="text" class="form-control" placeholder="" />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  renderFormaPago = () => {
    return <></>;
  };

  renderDelivery = () => {
    const { direcciones = [] } = this.props;

    return (
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title mb-4">Datos de envio</h4>
          <form>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Direcciones</label>
                {direcciones.length > 0 && (
                  <select
                    id="inputState"
                    class="form-control"
                    value={this.state.direccion_idx}
                    onChange={(event) =>
                      this.setState({ direccion_idx: event.target.value })
                    }
                  >
                    <option>Elegir...</option>
                    {direcciones.map((direccion, idx) => (
                      <option key={idx} value={idx}>
                        {direccion.ciudad} - {direccion.barrio}.{" "}
                        {direccion.direccion}
                      </option>
                    ))}
                  </select>
                )}
                <div style={{ paddingTop: "10px" }}>
                  <Link to="/perfil" class="btn btn-primary">
                    Nueva direccion
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  renderRetirar = () => {
    return (
      <div class="card mb-4">
        <div class="card-body">
          <h4 class="card-title mb-4">Pasar a buscar</h4>
          <form>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Sucursal</label>
                <select
                  id="inputState"
                  class="form-control"
                  value={this.state.sucursal_idx}
                  onChange={(event) =>
                    this.setState({ sucursal_idx: event.target.value })
                  }
                >
                  <option>Elegir...</option>
                  {[
                    "TRINIDAD",
                    "VILLA MORRA",
                    "VILLA MORRA 2",
                    "CARMELITAS",
                    "ESPAÑA",
                    "FERNANDO DE LA MORA",
                  ].map((s) => (
                    <option key={s} value={s}>
                      God's Pan {s}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  removeCarritoItem = (idx) => {
    let carrito_items = [...this.props.carrito_items];
    carrito_items.splice(idx, 1);
    this.props.setCarritoItems(carrito_items);
  };

  updateCarritoItem = (idx, cantidad) => {
    let carrito_items = [...this.props.carrito_items];

    carrito_items[idx]["cantidad"] = cantidad;

    this.props.setCarritoItems(carrito_items);
  };
}

const mapStateToProps = (state) => ({
  carrito_items: state.carrito.carrito_items,
  direcciones: state.auth.direcciones,
});

const mapDispatchToProps = (dispatch) => ({
  setCarritoItems: (carrito_items) => {
    dispatch(setCarritoItems(carrito_items));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CarritoPage);
